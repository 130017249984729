var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Button',{staticClass:"custom-btn",class:{
    mini: _vm.size === 'mini',
    'mini plain': _vm.size === 'mini-plain',
     small: _vm.size === 'small',
    'small plain': _vm.size === 'small-plain',
    large: _vm.size === 'large',
    full: _vm.size === 'full',
    'large plain': _vm.size === 'large-plain',
  },attrs:{"loading":_vm.loading,"disabled":_vm.loading,"loading-type":"spinner"},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }