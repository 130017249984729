import { post } from '@/utils/request';

export const fetchHomeData = async data => {
  return await post('/appdealer/api/channelDistributionMap/getIndexNum', data)
}

export const fetchIndexBanner = async data => {
  return await post('/appdealer/api/dealer/getIndexBanner', data)
}
export const fetchIndexOrderAccount = async data => {
  return await post('/appdealer/api/dealer/indexOrderAccount ', data)
}

export const fetchOrderData = async data => {
  return await post('/appdealer/api/report/indexOrderOverview', data)
}

// 查询经销商目标完成情况
export const fetchTarget = async data => {
  return await post('/appdealer/api/eb/target/progress', data)
}

export const fetchBalance = async data => {
  return await post('/appdealer/api/report/dealerBalance', data)
}

export const fetchFiList = async data => {
  return await post('/appdealer/api/report/dealerFiList', data)
}

export const fetchTeamData = async data => {
  return await post('/appdealer/api/saleman/getTotalData', data)
}

export const fetchTeamList = async data => {
  return await post('/appdealer/api/saleman/list', data)
}

export const fetchMaterial = async data => {
  return await post('/appdealer/api/material/getMaterialList', data)
}

export const fetchChartData = async data => {
  return await post('/appdealer/api/report/dealerSales', data);
}

export const fetchCustomSalesAnalysis = async data => {
  return await post('/appdealer/api/report/customSalesAnalysis', data);
}

export const getIndexNum = async data =>{
    return await post('/appdealer/api/channelDistributionMap/getIndexNum', data);
}

export const getDealerBankById = async data =>{
  return await post('/appdealer/api/dealer/getDealerBankById', data);
}

export const getDealerInvoiceInfo = async data =>{
  return await post('/appdealer/api/report/getDealerInvoiceInfo', data);
}
