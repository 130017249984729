import { post } from '@/utils/request';

export const fetchProductList = async data => {
  return await post('/appdealer/api/material/getMaterialList', data)
}

export const fetchProductListByClass = async data => {
  return await post('/appdealer/api/dealer/getProductInfoByClass', data)
}
export const collectProduct = async data => {
  return await post('/appdealer/api/materialCollect/collectMaterial', data)
}
export const fetchMaterialInfo = async data => {
  return await post('/appdealer/api/material/getMaterialInfo', data)
}
export const addShopCar = async data => {
  return await post('/appdealer/api/shopCar/addShopCar', data)
}
export const fetchShopCaList = async data => {
  return await post('/appdealer/api/shopCar/getShopCaList', data)
}
export const changeShopCarMaterial = async data => {
  return await post('/appdealer/api/shopCar/changeShopCarMaterial', data)
}
export const delShopCarMaterial = async data => {
  return await post('/appdealer/api/shopCar/delShopCarMaterial', data)
}