import { post,login } from '@/utils/request';

export const handleLogin = async data => {
  return await post('/appdealer/api/user/h5login', data)
}

export const sendCode = async data => {
  return await post('/appdealer/api/user/get_code', data);
}

export const handleForgetPwd = async data => {
  return await post('/appdealer/api/user/forget_pswd', data);
}

export const fetchOpenId = async data => {
  return await login('/wechat/jsapi/get_openid', data);
}

export const fetchDealerOpenId = async data => {
    return await login('/wechat/jsapi/getDealerOpenId', data);
}

export const handleChangePassword = async data => {
  return await post('/appdealer/api/user/modify_pswd', data);
}

export const handleSmsLogin = async data => {
    return await post('/appdealer/api/user/h5PhoneLogin', data)
}

export const handleGetCode = async data => {
    return await post('/appdealer/api/user/get_code', data)
}

//查询是否存在未确认的目标
export const getTarget = async data => {
    return await post('/appdealer/api/eb/target/get', data)
}

// 经销商目标确认
export const sureTarget = async data => {
    return await post('/appdealer/api/eb/target/sure', data)
}

export const getCrtificateList = async data => {
    return await post('/appdealer/api/dealer/getCrtificateList', data)
}

export const getVerificationBizToken = async data => {
    // return await post('/appdealer/api/dealer/getVerificationBizToken', data)
    return await post('/wechat/wx/other/getVerificationBizToken', data)
}

export const getVerificationBizResult = async data => {
    // return await post('/appdealer/api/dealer/getVerificationBizResult', data)
    return await post('/wechat/wx/other/getVerificationBizResult', data)
}

