import { post } from '@/utils/request';

export const submitFeedback = async data => {
  return await post('/appdealer/api/user/save_retroaction', data)
}

export const fetchDictList = async data => {
  return await post('/appdealer/api/dict/get_DictList', data);
}

export const fetchDealerInfo = async data => {
  return await post('/appdealer/api/user/getDealerInfo', data);
}

export const fetchDealerLogout = async data => {
  return await post('/appdealer/api/user/logout', data);
}

export const fetchTotalData = async data => {
  return await post('/appdealer/api/saleman/getTotalData', data);
}
