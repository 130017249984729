 <template>
  <div>
    <Sku
        v-model="show"
        :sku="sku"
        :goods="goods"
        :goods-id="goodsId"
        :hide-stock="sku.hide_stock"
        :message-config="messageConfig"
        :reset-stepper-on-hide="true"
        @buy-clicked="onBuyClicked"
        @add-cart="onAddCartClicked"
        @stepper-change="onChangeNumber" 
        
    >
      <template #sku-header>
        <div class="van-sku-header van-hairline--bottom">
          <div class="van-image van-sku-header__img-wrap">
            <img style="width: 100px;height: 120px;" :src="product.smallUrl">
          </div>
          <div class="van-sku-header__goods-info" style="text-align: left">
            <div class="van-sku__goods-price">
              <span class="van-sku__price-num" style="color: #2c2c2c;font-weight: bold;font-size: 16px;">{{product.materialName}}</span>
            </div>
            <div class="van-sku-header-item">
              <span style="color: red;font-size: 12px;">如显示暂无价格,请选择发货公司及运输方式</span>
            </div>
            <div class="van-sku-header-item">
              <span style="color: red;font-size: 16px;font-weight: bold;">￥:{{price}}</span>
            </div>
          </div>
        </div>
      </template>
  
      <template #sku-group>
        <div class="van-sku-messages" style="padding-bottom: 0;padding-top: 10px;">
          <div class="van-sku-messages__cell-block">
  
            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;">
              <div class="van-cell__title van-field__label" style="width: 40px;margin-right: 5px;">
                <span>品牌:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.brandName}}</span>
                </div>
              </div>
            </div>
  
            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;">
              <div class="van-cell__title van-field__label" style="width: 40px;margin-right: 5px;">
                <span>工艺:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.materialClassName || product.materialClass}}</span>
                </div>
              </div>
            </div>
  
            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;">
              <div class="van-cell__title van-field__label" style="width: 40px;margin-right: 5px;">
                <span>配比:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.matchEq}}</span>
                </div>
              </div>
            </div>
  
            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;">
              <div class="van-cell__title van-field__label" style="width: 40px;margin-right: 5px;">
                <span>包重:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.bagWeightName || product.bagWeight}}</span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="van-sku-row van-hairline--bottom" style="display: flex;margin: 0;padding: 10px;height: 40px;">
            <div class="van-sku-row__title" style="width: 80px;line-height: 40px;padding-right: 20px">发货公司:</div>
            <span class="van-sku-row__item van-sku-row__item--active" style="width: auto;margin: auto 8px" @click="onClickFactory">
                <div class="van-sku-row__item-name">{{factoryText}}</div>
              </span>
          </div>
  
          <div class="van-sku-row van-hairline--bottom" style="display: flex;margin: 0;padding: 10px;height: 40px;">
            <div class="van-sku-row__title" style="width: 80px;line-height: 40px;padding-right: 20px">运输方式:</div>
            <span class="van-sku-row__item " @click="onClickTranMode('20','汽运')" :class="tranMode === '20' ? 'van-sku-row__item--active' : ''" style="width: 60px;margin: auto 8px">
                <div class="van-sku-row__item-name">汽运</div>
              </span>
            <span class="van-sku-row__item" @click="onClickTranMode('30','船运')" :class="tranMode === '30' ? 'van-sku-row__item--active' : ''" style="width: 60px;margin: auto 8px">
                <div class="van-sku-row__item-name">船运</div>
              </span>
            <span class="van-sku-row__item" @click="onClickTranMode('10','铁运')" :class="tranMode === '10' ? 'van-sku-row__item--active' : ''" style="width: 60px;margin: auto 8px">
                <div class="van-sku-row__item-name">铁运</div>
              </span>
          </div>
        </div>
  
  
      </template>
  
  
    </Sku>
  
  
    <Popup v-model="showFactory" position="bottom" :style="{ height: '30%' }">
      <Picker title="发货公司" show-toolbar :columns="factoryList" input-align="right"
              @confirm="onConfirmFactory"
              @cancel="onCancelFactory"
      />
  
    </Popup>

    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>
<script>

import {Sku,Popup,Picker,Loading } from 'vant';
import {fetchDictList} from "@/api/common";
import {fetchMaterialPrice, fetchProductList} from "@/api/select";
import {getDealerId} from "@/utils/user";
import {addShopCar} from "@/api/product";

export default {
  components: {
    Sku,
    Popup,
    Picker,
    Loading,
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showAddCar: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    showAddCar: {
      handler() {
        this.show = this.showAddCar;
      },
      deep:true
    },
    show: {
      handler() {
        if (!this.show) {
          this.rest();
          
          this.$emit('hidden', this.show);
        }
      },
      deep:true
    },
    
  },
  data() {
    return {
      show: this.showAddCar,
      factoryList: [],
      produceList: [],
      isLoading: false,
      showFactory: false,
      factoryText: '点击选择发货公司',
      factory: null,
      tranMode: '20',
      tranModeText: '汽运',
      price: '暂无价格',
      number: 1,
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [

        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [

        ],
        price: '22.00', // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        messages: [

        ],
        hide_stock: false // 是否隐藏剩余库存
      },
      goods: {
        // 默认商品 sku 缩略图
        picture: this.product.smallUrl
      },
      goodsId: null,
      messageConfig: {
        // 数据结构见下方文档
      },
    }
  },

  activated() {
    
  },
  created() {
    fetchDictList({
      "type": 'eb_factory'
    }).then(response => {
      response.data.forEach(item => {
        this.factoryList.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    })
  },
  methods: {
    onBuyClicked() {
      this.produceList = [];
      if (this.factoryText === '点击选择发货公司') {
        this.$toast('请选择发货公司')
        return;
      }
      console.log(this.product)

      const param = {
        "dealerId": getDealerId(),
        "factory": this.factory || '10',
        "tranMode": this.tranMode || '20',
        "materialCode": this.product.materialCode,
      }
      //查物料是否绑定
      fetchProductList(param).then(res => {
        if (res.data && res.data.length > 0) {
          res.data[0].applyNumber = this.number;
          this.produceList.push(res.data[0]);
          this.isLoading = false;
          this.$router.push({
            name: 'OrderAddOrderFirst',
            params: {
              seller: 'self',
              settlementProduct: this.produceList,
              factory: this.factory || '10',
              tranMode: this.tranMode || '20',
              factoryValue: this.factoryText || '临沭',
              tranModeValue: this.tranModeText || '汽运',
            }
          })
        }
      })
    },
    onAddCartClicked() {
      if (this.factoryText === '点击选择发货公司') {
        this.$toast('请选择发货公司')
        return;
      }
      addShopCar({
        "dealerId":getDealerId(),
        "factory":this.factory,
        "tranMode":this.tranMode,
        "num":this.number,
        "materialId":this.product.materialCode
      }).then(() => {
        this.$toast('添加成功')
        this.rest();
        this.$emit('hidden', this.show);
      })
      
      
      
    },
    
    rest() {
      this.factoryText = '点击选择发货公司';
      this.factory = null;
      this.tranMode = '20';
      this.price = '暂无价格';
      this.number = 1;
    },

    onChangeNumber(val) {
      this.number = val;
    },
    onClickFactory() {
      this.showFactory = true;
    },
    onConfirmFactory(e) {
      this.factory = e.value;
      this.factoryText = e.text;
      this.showFactory = false;

      this.getMaterialPrice();
    },

    getMaterialPrice() {
      this.isLoading = true;
      fetchMaterialPrice({
        "dealerId": getDealerId(),
        "factory": this.factory || '10',
        "tranMode": this.tranMode || '20',
        "materialCode": this.product.materialCode,
      }).then(res => {
        this.isLoading = false;
        this.price = res.data.price;
      }).catch(err => {
        this.isLoading = false
        this.$toast(err.message)
      });
    },
    
    onCancelFactory() {
      this.showFactory = false;
    },

    onClickTranMode(val,text) {
      this.tranMode = val;
      this.tranModeText = text;
      if (this.factoryText != '点击选择发货公司') {
        this.getMaterialPrice();
      }

    },
    
  },
};
</script>
<style lang="scss" scoped>
.loading-container {
  width: 100%;
  height: 100%;
  background: #7c7a7a;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
}
</style>
