<template>
  <div class="home-page" @scroll.passive="getScroll($event)">

    <Search v-model="searchTotal" shape="square" class="search" @input="onSearch" placeholder="请输入搜索关键词" >
      <!-- 搜索框左图标 -->
      <template v-slot:right-icon>
        <Icon @click="onMsg" name="chat-o"  v-if="messageSum > 0" :badge="messageSum" style="margin-right: 30px;display: inline;" size="1.6rem" />
        <Icon @click="onMsg" name="chat-o"  v-else style="margin-right: 15px;display: inline;" size="1.6rem" />
        <Icon @click="onService" name="service"  style="margin-right: 0px;display: inline;" size="1.6rem" />
      </template>
     
    </Search>

    <Swipe :autoplay="3000" class="swipe">
      <SwipeItem v-for="(image, index) in bannerData" :key="index">
        <img class="img"  :src="image.photoUrl"/>
      </SwipeItem>
    </Swipe>

    <div class="mySdl" id="apply1">
      <div class="header">
        <span class="title">我的账款</span>
      </div>
      <div class="account">
        <div class="info">
          <div class="money">可用余额/元：<span style="color: #2c2c2c;font-weight:bold;font-size: 22px;">{{ homeData.account.kyyue }}</span></div>
          <div class="rebate">可用返利/元：<span style="color: #2c2c2c;font-weight:bold;font-size: 22px;">{{ homeData.account.backPrice }}</span></div>
        </div>
        <div class="button-container">
          <Button class="button" size="mini" @click="$router.push({name: 'homeBankDetail'})">我要汇款</Button>
          <Button class="button" size="mini" style="margin-top: 8px;" @click="$router.push({name: 'InfoAccountBalance'})">余额明细</Button>
        </div>
      </div>
    </div>
    <div class="mySdl" style="margin-top: 10px;">
      <div class="header">
        <span class="title">我的订单</span>
      </div>
      <div class="account">
        <div class="info">
          
          <div class="dashboard">
            <div class="item" @click="onClickOrder(3)">
              <div class="num">{{ homeData.order.applyNumberYear }}</div>
              <div class="label">全部订单</div>
            </div>
            <div class="divider"/>
            <div class="item" @click="onClickOrder(0)">
              <div class="num">{{ homeData.order.unSendNumberYear }}</div>
              <div class="label">待发货</div>
            </div>
            <div class="divider"/>
            <div class="item" @click="onClickOrder(2)">
              <div class="num">{{ homeData.order.sendNumberYear }}</div>
              <div class="label">已发货</div>
            </div>
          </div>
         
        </div>
        <div class="button-container">
          <Button class="button order"  size="mini" @click="$router.push({name: 'productCar'})">购物车</Button>
          <Button class="button order"  size="mini" style="margin-top: 8px;" @click="$router.push({name: 'OrderAddOrderFirst', params: {seller: 'self'}})">立即下单</Button>
        </div>
      </div>
    </div>


    <div class="product">
      <div style="display: flex;width: 100%;">
        <div style="width: 55%;height: 60px;" >
          <Tabs class="tabs" v-model="active" @click="onClickProductCategory" line-width="30px">
            <Tab  title="我的产品" />
            <Tab  title="我的收藏" />
          </Tabs>
        </div>
        <div style="width: 45%;height: 60px;padding-right: 10px;" >
          <DropdownMenu>
            <DropdownItem  @change="onClickBrand" :title="brand"  v-model="brandValue" :options="brandOption" />
            <DropdownItem  @change="onClickFactory" :title="factory" v-model="factoryValue" :options="factoryOption" />
          </DropdownMenu>
        </div>
      </div>

      <div style="display: flex;justify-content: space-between;">
        <div class="oneNav" style="">
          <Sidebar v-model="activeKey" @change="onChangeMaterialClass">
            <SidebarItem  v-for="(item,index) in materialClassList" :key="index" :title="item.text" />
          </Sidebar>
        </div>
        <div class="twoNav">
          <Card
              desc=""
              v-for="(item,index) in productList"
              :key="index"
              :title="item.materialName"
              :thumb="checkImgExists(item.smallUrl)"
              @click-thumb="onClickProduct(item.materialCode,item.isCollect)"
          >

            <template #tags>
              <div class="card-handle">
                <div class="collect" @click="onClickAddCar(item)"><div class="van-icon van-icon-balance-o " style="color: #CD001F;"></div><div style="width: 30px">价格</div></div>
                <div class="collect" @click="onClickCollect(item.materialCode,index)"><div class="van-icon van-icon-star " :style="item.isCollect && item.isCollect === '1' ? 'color: rgb(255, 80, 0);' : 'color: rgb(200,200,200);'"></div><div style="width: 30px">收藏</div></div>
                <button class="car" @click="onClickAddCar(item)">
                  <div class="van-icon van-icon-cart-o van-goods-action-icon__icon car-icon"/>
                </button>
                <button class="buy" @click="onClickAddCar(item)">
                  <div class="buy-icon">
                    购买
                  </div>
                </button>
              </div>
              
            </template>
            
          </Card>


          <infinite-loading ref="infinite" @infinite="infiniteHandler">
            <span slot="no-more">
              暂无更多
            </span>
          </infinite-loading>
          
        </div>
      </div>
      
      
    </div>
    <Car :showAddCar.sync="showAddCar" :product="product" @hidden="onHiddenCar"></Car>
    
    <Suspension v-if="false"  :position="position" :needNearEdge="true"/>

   

    <Popup  v-model="showPublicity" :overlay-style="{'z-index': 99999}"   :style="{width: '90%', height: '80%','z-index': 999999,'background': '#FAFBFC' }" :close-on-click-overlay="false" round>
      <div class="publicity">
        <div class="header">
          <span class="title">您的宣传品已出库，请签收确认！</span>
        </div>
        <div class="content">
          <ContactsCard class="card" v-for="(item, index) in publicityData" :key="index" :info="item" :config="item.config" />
            
        </div>
        <Field
            v-model="publicityForm.feedback"
            rows="3"
            :autosize="{ maxHeight: 76}"
            clearable
            label="异常反馈"
            type="textarea"
            placeholder="请输入异常反馈"
        />
        <div class="btn-container">
          <CustomButton size="large" @click="confirmPublicity">确认</CustomButton>
        </div>
      </div>
    </Popup >

    <Popup  v-model="showService" @click-overlay="closeService" @close="closeService" :overlay-style="{'z-index': 999999}" :style="{width: '90%', height: '40%','z-index': 9999999,'background': '#FAFBFC' }" closeable round>
      <div class="publicity">
        <div class="header">
          <span class="title">服务电话</span>
        </div>
        <div class="content">
          <div class="label-list-container">
            <div class="label-value-container" v-for="(item, index) in serviceList" :key="index">
              <div class="label">{{item.dictLabel}}</div>
              <div class="value" v-if="item.dictLabel.indexOf('电话') != -1" @click="callPhone(item.dictValue)">{{item.dictValue}}</div>
              <div class="value" v-else>{{item.dictValue}}</div>
              <div v-if="item.dictLabel.indexOf('电话') != -1"><img src="../../assets/images/my/tel.png" class="label-icon" /></div>
            </div>
            <div class="label-value-container" style="padding-top: 20px;"> 
              <div class="label" style="color: #818181;">提示：可点击首页右上角客服标识查看</div>
            </div>
          </div>

        </div>
        
        <div class="btn-container">
          <CustomButton size="large" @click="closeService">确认</CustomButton>
        </div>
      </div>
    </Popup >
    
    
    
    <!-- tabbar -->
    <Tabbar />
  </div>
</template>

<script>
import * as user from '@/utils/user';
import { fetchIndexBanner,fetchIndexOrderAccount } from '@/api/home';
import {fetchOrderLogisticsNoticeSum,getUnConfirmPublicity, confirmPublicity} from "@/api/order";
import {fetchDealerLogout, fetchDictList} from "@/api/common";
import {fetchProductListByClass,collectProduct} from "@/api/product";
import {fetchDealerOpenId, getVerificationBizResult} from '@/api/login';

import InfiniteLoading from "vue-infinite-loading";

import { Dialog } from 'vant';
import Tabbar from '../../components/common/tabbar/index';
import Button from '../../components/common/button/CustomButton';
import Suspension from '../../components/suspension/index';
import Car from '../../components/car/index';
import ContactsCard from '@/views/info/components/contactsCard.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'



import { Search,Icon,Swipe,SwipeItem,Tab,Tabs,Sidebar,SidebarItem,Card,Popup,Field,DropdownMenu, DropdownItem   } from 'vant';
export default {
  components: {
    Tabbar,
    Search,
    InfiniteLoading,
    Icon,
    Swipe,
    SwipeItem,
    Button,
    Tab,
    Tabs,
    Sidebar,
    SidebarItem,
    Card,
    Suspension,
    Car,
    Popup,
    ContactsCard,
    CustomButton,
    Field,
    DropdownMenu,
    DropdownItem
  },
  
  data() {
    return {
      nick: user.getNickName(),
      role: user.getUserType(),
      openId: user.getOpenId(),
      searchTotal: null,
      messageSum: 0,
      type: "1",
      active: 0,
      activeKey: 0,
      materialClassList: [
        {
          "value": "new",
          "text": "新品"
        }
      ],
      materialClassValue: 'new',
      pageNo: 1,
      pageSize: 5,
      total: 0,
      productList:[
        
      ],
      homeData: {
        account: {
          
        },
        order: {
          
        }
      },
      bannerData:[
          // 'http://shidanli.oss-cn-qingdao.aliyuncs.com/api/542B07582B6D4A1D83FF0D82968D59B5.webp',
          // 'http://shidanli.oss-cn-qingdao.aliyuncs.com/api/0FA07DB936AA435D989863F8A7456088.webp'
      ],
      position: {
        top: '62vh',
        right: 0
      },
      // 滚动前，滚动条距文档顶部的距离
      oldScrollTop: 0,
      showSuspension: true,
      showAddCar: false,
      product: {},
      publicityForm: {},
      publicityData:[],
      showPublicity: false,
      showService: false,
      serviceList: [],

      brand: '品牌',
      factory: '工厂',
      brandValue: '',
      factoryValue: '',
      brandOption: [
        { text: '全部', value: '' },
      ],
      factoryOption: [
        { text: '全部', value: '' },
      ],
    }
  },
  async activated() {
    
    var userName = user.getUserName();
    var lastUserName = user.getLastUserName() ? user.getLastUserName() : "";
    if (userName !== lastUserName) {
      this.showService = true;
    }
    
    await this.fetchService();

    this.getUnConfirmPublicity();
    
    const res = await fetchIndexBanner();
    this.bannerData = res.data;

    const res2 = await fetchIndexOrderAccount();
    this.homeData = res2.data;

    await this.fetchOrderLogisticsNoticeSum();

    await this.fetchMaterialClass();
    await this.fetchBrand();
    await this.fetchFactory();

    //置空查询条件
    window.localStorage.setItem("shidanli-filter", "");
    window.localStorage.setItem("agroDetailFilterAncestors", "");
  },
  async mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.handleScroll)

  },
  created() {
    console.log(this.openId)
    
    if (this.$route.query.BizToken && this.$route.query.openId) { //人脸识别通过 查询结果
      getVerificationBizResult({
        bizToken: this.$route.query.BizToken,
        openId: this.$route.query.openId
      }).then(res => {
        if (res.msg != '操作成功') {
          this.$toast(res.msg);
          setTimeout(() => {
            fetchDealerLogout();
            user.logout()
            window.location.replace('/login')
          }, 2000) // 延迟一秒执行
        }
      })
    } else {
      if (!this.openId || this.openId == 'no_open_id') {
        this.$toast("您的登录已过期，请重新登录！");
        setTimeout(() => {
          fetchDealerLogout();
          user.logout()
          window.location.replace('/login')
        }, 2000) // 延迟一秒执行
      } else {
        this.getOpenId();
      }
    }
  },
  methods: {

    /**
     * 获取openid
     */
     getOpenId() {
       
       fetchDealerOpenId({ dealerId: user.getUserName() }).then(res => {
         if (!res.data.loginVerifyType || '0' == res.data.loginVerifyType || '1' == res.data.loginVerifyType) {
           return;
         }
         if (user.getOpenId() != res.data.dealerOpenId || user.getOpenId() != res.data.certificateOpenId) {
           Dialog.alert({
             message: '实名认证',
           }).then(() => {
             sessionStorage.clear();
             this.$router.replace({ name: "Certificate", params: { openId: this.openId } });
           });
         }
       }).catch(e => {
         console.log(e);
         this.$toast("查询当前用户openId信息异常，请返回公众号重新点击登录！");
       });
    },


    /**
     * 重置列表
     */
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.pageNo = 1;
      this.total = 0;
      this.productList = [];
    },

    async infiniteHandler($state) {
      try {
        await this.fetchProductInfoByClass();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      console.log("total："+this.total)
      console.log("productList："+this.productList)
      if (this.total - this.productList.length === 0) {
        $state.complete();
      }
    },

    async fetchProductInfoByClass() {
      const res = await fetchProductListByClass({
        "type": this.type,
        "searchTotal": this.searchTotal,
        "factory": this.factoryValue,
        "brandId": this.brandValue,
        "materialClass": this.materialClassValue === "0" ? null : this.materialClassValue,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      });
      this.productList = this.productList.concat(res.data || []);
      this.total = (res.page && res.page.total) || 0;
    },

    getUnConfirmPublicity() {
      console.log(1212112)

      getUnConfirmPublicity().then(res => {
        console.log(res)
        if (res.status == 0 && res.data && res.data.length > 0) {
          this.publicityData = res.data.map(d => {
            return {
              ...d,
              config: [
                {label: '出库时间:', field: 'sendDate'},
                {label: '宣传品名称:', field: 'materialName'},
                {label: '出库数量:', field: 'sendQty'},
              ]
            }
          });
          this.showPublicity = true;
        }
      });
    },

    confirmPublicity() {
      if (this.publicityData && this.publicityData.length > 0) {
        var ids = this.publicityData.map(item => item.id).join(",");
        confirmPublicity({ids: ids,feedback: this.publicityForm.feedback}).then(res => {
          if (res.status == 0) {
            this.$toast('确认成功！');
            this.showPublicity = false;
          } else {
            this.$toast(res.msg);
            return;
          }
        });
      } else {
        this.$toast('您目前没有需要确认的宣传品！');
        return;
      }
    },

    onChangeMaterialClass(index) {
      this.materialClassValue = this.materialClassList[index].value;
      this.resetList();
    },

    async fetchMaterialClass() {
      let response = await fetchDictList({
        "type": 'eb_material_class'
      })
      response.data.forEach(item => {
        this.materialClassList.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },

    async fetchBrand() {
      let response = await fetchDictList({
        "type": 'eb_brand'
      })
      response.data.forEach(item => {
        this.brandOption.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },

    async fetchFactory() {
      let response = await fetchDictList({
        "type": 'eb_factory'
      })
      response.data.forEach(item => {
        this.factoryOption.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    
    onClickBrand(value) {
      this.resetList();
      this.brand = this.brandOption.filter(i => i.value == value)[0].text;
    },

    onClickFactory(value) {
      this.resetList();
      this.factory = this.factoryOption.filter(i => i.value == value)[0].text;
    },

    onMsg () {
      this.$router.push("Msg");
    },

    onService () {
      this.showService = true;
    },
    closeService () {
      user.setLastUserName(user.getUserName());
      this.showService = false;
    },
    
    onSearch (val) {
      if (val) {
        //下滑到指定位置
        var el=document.getElementsByClassName("product")[0];
        this.$nextTick(function () {
          window.scrollTo({"behavior":"smooth","top":el.offsetTop});
        })
      }
      this.searchTotal = val;
      this.resetList();
      
    },

    onClickProductCategory(name,title) {
      var typeOld = this.type;
      if ('我的产品' === title) {
        this.type = '1';
      } else {
        this.type = '3';
      }
      if (typeOld != this.type) {
        this.resetList();
      }
    },
    onClickProduct(id,isCollect) {
      this.$router.push({ name: "productDetail",params: { id:id,isCollect: isCollect} });
    },
    onClickCollect(materialCode,index) {
      collectProduct({"materialId": materialCode}).then(() => {
        this.productList[index].isCollect = this.productList[index].isCollect === '0' ? '1' : '0';
      });
    },
    onClickAddCar(item) {
      this.product = item;
      this.showAddCar = true;
      console.log(item)
    },
    onBuyClicked() {
      console.log('立即购买')
    },
    onAddCartClicked() {
      console.log('加入购物车')
    },

    onHiddenCar() {
      this.showAddCar = false;
    },
    
    handleScroll() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
          document.body.scrollTop
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (scrollStep < 0) {
        //滚动条向上滚动了 显示
        if (!this.showSuspension) {
          this.showSuspension = true;
        }
      } else {
        //滚动条向下滚动了 隐藏
        if (this.showSuspension) {
          this.showSuspension = false;
        }

      }

    },

    async fetchOrderLogisticsNoticeSum() {
      try {
        let response = await fetchOrderLogisticsNoticeSum();
        if (response.status == 0) {
          this.messageSum = response.data || 0;
          if (this.messageSum > 99) {
            this.messageSum = 99;
          }
        } else {
          this.$toast(response.message);
        }
      } catch (err) {
        this.$toast(err.message);
      }

    },

    checkImgExists(imgurl) {
      var imgObj = new Image() // 判断图片是否存在
      imgObj.src = imgurl
      // 存在图片
      if (imgObj.fileSize > 0 || (imgObj.width > 0 && imgObj.height > 0)) {
        return imgurl;
      } else {
        return 'https://shidanli.oss-cn-qingdao.aliyuncs.com/product/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230320151908.png';
      }
    },

    onClickOrder(active) {
      this.$router.push({path: '/order/index?active='+active})
    },

    async fetchService() {
      let response = await fetchDictList({
        "type": 'sys_service_phone'
      })
      this.serviceList = response.data;
    },

    callPhone(phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    }

    
    
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #FAFBFC;
  z-index: 10;
  padding-bottom: 92px;
  box-sizing: border-box;

  .publicity {
    height: 100%;
    
  
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 10px 10px 10px;
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 17px;
        color: #2C2C2C;
      }
    }
    .content {
      height: 67%;
      overflow:auto;
      .card {
        margin-top: 5px;
      }

      .label-list-container {
        padding: 10px 20px;
        .label-value-container {
          width: 100%;
          height: 20px;
          display: flex;
          //align-items: center;
          font-size: 16px;
          &:not(:last-child){
            margin-bottom: 6px;
          }
          .label {
            color: #2C2C2C;
            justify-content: flex-start;
            display: flex;
            align-items: center;
            font-weight: bold;
          }
          .value {
            color: #2C2C2C;
            margin-left: 6px;
            font-weight: bold;
            flex:1;
            justify-content: flex-end;
            display: flex;
            align-items: center;

          }
          .label-icon {
            width: 17px;
            height: 17px;
            padding-left: 5px;
          }
        }
      }
      
      
    }
    
    .btn-container {
      box-sizing: border-box;
    }
    
  }
  
  .search {
    background-color: #ffffff;
    padding: 7px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
  }
  
  .van-search {
    .van-field {
      height: 40px;
      line-height: 40px;
      padding-top: 2px;
      .van-info {
        margin-top: 10px;
      }
    }
  }

  .swipe {
    margin-top: 60px;
    .img {
      width: 97%;
      height: 160px;
    }
  }


  .mySdl {
    margin: 0px 7px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    box-sizing: border-box;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /*border-bottom: 1px solid #EAEAEA;*/
      padding: 10px;
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 17px;
        color: #2C2C2C;
      }
    }
   

    .account {
      //border-top: 1px solid #EAEAEA;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }
      .info {
        margin-left: 8px;
        text-align: left;
        height: 70px;
        flex: 1;
        .money {
          padding-top: 4px;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #818181;
        }
        .rebate {
          padding-top: 14px;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #818181;
        }

        .dashboard {
          background: #FFFFFF;
          display: flex;
          align-items: center;
          height: 50px;
          padding: 0 20px 0 10px;
          justify-content: space-between;
          box-sizing: border-box;
          .item {
            text-align: center;
          }
          .divider {
            width: 1px;
            height: 60px;
            background: #e1e1e1;
            margin-top: 10px
          }
          .num {
            font-size: 18px;
            font-weight: bold;
            line-height: 21px;
            color: #2C2C2C;
            margin-top: 10px;
          }
          .label {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color: #818181;
            margin-top: 10px;
          }
        }
      }
      .button-container {
        width: 90px;
        margin-top: -20px;
        /*display: flex;*/
        /*align-items: center;*/
        /*justify-content: space-between;*/
        .button {
          background-color: #FFA240;
          display: block;
          width: 80px;
          height: 30px;
          padding-left: 0;
          padding-right: 0;
          margin: 1px 0px;
        }
        .order {
          background-color: #33CC67;
        }
      }
    }

  }
  
  .product {
    margin: 7px 7px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    box-sizing: border-box;


    .tabs {

      ::v-deep .van-tabs__wrap {
        height: 50px;
        .van-tabs__nav {
          background-color: transparent;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .van-tab {
            height: 40px;
            font-size: 18px;
            margin: 0 10px;
            opacity: 0.6;

            &.van-tab--active {
              opacity: 1;
            }
            
            .van-tab__text {
              display: block;
              height: 40px;
              line-height: 40px;
              padding-top: 10px;
            }
          }

          .van-tabs__line {
            bottom: 0px;
          }
        }
      }
    }

    ::v-deep .van-dropdown-menu .van-dropdown-menu__bar { 
      height: 60px;
      background-color: #fff;
      box-shadow: unset !important;
    }

    .oneNav {
      width: 22%;
      height: 500px;
      overflow: scroll;
    }
    
    .twoNav {
      width: 77%;
      height: 500px;
      overflow: scroll;
      
      .van-card__title {
        text-align: left;
      }
      
      .card-handle {
        display: flex;
        padding-top: 25px;
      }
      
      .collect {
        width: 30px;
        background-color: transparent;
       
      }
      
      .car {
        background: #FFA240;
        margin-left: 5px;
        margin-right: 0;
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;

        height: 30px;
        border: none;
        line-height: 30px;

        .car-icon {
          color: #fff;
          line-height: 30px;
          width: 26px
        }
      }
      
      .buy {
        background: #33CC67;
        margin-right: 5px;
        margin-left: 0;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;

        height: 30px;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        line-height: 30px;
        border: none;

        .buy-icon {
          width: 26px
        }
      }
    }
  }

}
</style>
