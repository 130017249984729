<template>
  <div class="self-card-container" @click.stop="$emit('cardClick')">
    <div class="self-card">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.self-card-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  .self-card {
    width: 100%;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    padding: 0 12px;
  }
}
</style>